
body { 
  font-family: "Helvetica Neue", Helvetica,Arial, sans-serif; color:black;
   font-size:  calc(0.5em + 0.6vh);
}
@media (max-width: 600px) {
 body{
  font-size:  calc(0.5em + 0.3vh);


 }
}
.i {
  height: 14px;
  margin: 0 10px;
}
.nav-black{
  color: black;
}
.navLinkColor
  {
    cursor: pointer;
    height: 14px;
    margin: 0 25px;
    color: white;
  }
.navLinkBell
  {
    cursor: pointer;
    height: 14px;
    color: white;
  }
#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
  background-color:  #7386D5;
  color: white;
}
.sidebar-link-item{
  background-color:  #7386D5;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }

}

.d-flex {
  display: flex !important;
}
.page-popup{
  position:absolute; 
  z-index:30;
  display:none;
  padding: 10px; 
  width:95%;
  top: 70px;
  overflow-y: auto;

  /*-moz-border-radius: 5px; -webkit-border-radius: 5px; */
 }
 .page-popup-overlay {
     position:fixed;
  z-index:20;
 
     /* color with alpha channel */
     background-color: rgba(0, 0, 0, 0.8); /* 0.7 = 70% opacity */
     
  width:100%;
     /* stretch to screen edges */
     top: 0;
     left: 0;
     bottom: 0;
     right: 0;
 }
 .row{ padding: 5px; }
 .btn { margin: 3px; }


 body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}
.navbar
{
    
 /*   background-color: rgba(0,0,0,0.5); 
 background: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,1));*/
  background: -webkit-linear-gradient(to right, #7579ff, #b224ef);
  background: -o-linear-gradient(to right, #7579ff, #b224ef);
  background: -moz-linear-gradient(to right, #7579ff, #b224ef);
  background: linear-gradient(to right, #7579ff, #b224ef);
    border-radius:4px;
    color:#FFF;
    margin: 2px;
}
.link{
  cursor: pointer;
  color: #337ab7;
  margin-right: 5px;
}
.link:hover{
  cursor: pointer;
  color: #7579ff
}
.link-calendar{
  cursor: pointer;
}
.link-calendar:hover{
  cursor: pointer;
  color: white;
}
.link-red{
  cursor: pointer;
  color: #d10c0c;
  margin-right: 5px;
}
.link-red:hover{
  cursor: pointer;
  color: #b41e1e
}
.link-white{
  cursor: pointer;
  color: #FFF;
  margin-right: 5px;
}
.link-white:hover{
  cursor: pointer;
  color: #f3f3f3
}
.linkGreen{
  cursor: pointer;
  color: #da7e15;
  margin-right: 5px;
}
.linkGreen:hover{
  cursor: pointer;
  color: #f09328
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 2s linear infinite;
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 30%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loader-overlay {
    position:fixed;
     z-index:80;

    /* color with alpha channel */
    background-color: rgba(0, 0, 0, 0.7); /* 0.7 = 70% opacity */
    
 width:100%;
    /* stretch to screen edges */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.card-green{border-color:#bcdba3; margin-bottom: 10px}.card-green>.card-header{color:#000;background-color:#b3ffb3;border-color:#99ccff}.card-green>.card-header+.card-collapse>.card-body{border-top-color:#337ab7}
.card-blue{border-color:#80bfff; margin-bottom: 10px}.card-blue>.card-header{color:#000;background-color:#99ccff;border-color:#99ccff}.card-blue>.card-header+.card-collapse>.card-body{border-top-color:#337ab7}
.card-red{border-color:#d10c0c; margin-bottom: 10px}.card-red>.card-header{color:#000;background-color:#e64343;border-color:#d10c0c}.card-red>.card-header+.card-collapse>.card-body{border-top-color:#d10c0c}
.border-success{margin-bottom: 10px}
.nav-link{ cursor: pointer; }
.right-spaced{display: flex; justify-content: space-around}

.form-control{ min-width: 100px;}
@media (max-width: 786px) {
  .table {
    flex-wrap: wrap;
  }
  .table .col {
    width: 50%;
  }
}
.ql-container {
  min-height: 30rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}
.table-container {
    height: 10em;
}/*
tableMain table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
}
.table-container thead {
    /* head takes the height it requires, 
    and it's not scaled when table is resized */
  /*  flex: 0 0 auto;
    width: calc(100% - 0.9em);
}
.table-container tbody {
    /* body takes all the remaining available space */
 /*   flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
}
.table-container tbody tr {
    width: 100%;
}
.table-container thead, .table-container tbody tr {
    display: table;
    table-layout: fixed;
}
*/

.tableMain   { overflow: auto; height: 600px; }
.tableMain th { position: sticky; top: 0; background-color: #FFF; }

.datepicker__title {
  position: absolute;
  top: 0px;
  margin-left: 20px;
  font-weight: 400;
  display: block;
}
.input{
  margin-left: 115px;
  margin-right: 20px;
  padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    flex: 1 1 auto;
    width: 40%;
    min-width: 0;

}
.table-primary-subtle
{
  background-color: #000000 !important;
}