body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.form-control{ min-width: 120px !important}
@media (max-width: 786px) {
  .table {
    flex-wrap: wrap;
  }
  .table .col {
    width: 50%;
  }
}
.nav-link{ cursor: pointer; }
.table-warning
{
  background-color: #000000 !important;
}