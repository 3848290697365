#loginPage { background-image: url('./assets/images/medical.jpg');
    background-repeat: no-repeat; 
background-size: cover; 
box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.9); 
width: 100%; height: 100%; position: fixed;  margin: 0px; 
} 
#loginSvg{
    font-size: 8em; color: #0091ea
}
#footer {
position: fixed;
bottom: 0;
width: 100%;
}
@media screen and (min-width: 536px) {
#card {

width:500px; margin:50px auto 
}
}

  .page-popup1{
    position:absolute; 
    z-index:10;
    visibility:hidden;
    padding: 10px; 
    width:95%;
    top: 70px;
    /*-moz-border-radius: 5px; -webkit-border-radius: 5px; */
   }
   .page-popup-overlay1 {
       position:fixed;
    z-index:8;
   
       /* color with alpha channel */
       background-color: rgba(0, 0, 0, 0.5); /* 0.7 = 70% opacity */
       
    width:100%;
       /* stretch to screen edges */
       top: 0;
       left: 0;
       bottom: 0;
       right: 0;
   }